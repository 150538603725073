<template>
    <div class="thematicLibrary">
        <div class="con">
            <ul>
                <li class="item" @click="showConfig">
                    <span class="iconfont iconanalysis-chart"></span>
                    <span class="text-info">销售管理</span>
                </li>
                <li class="item" @click="showConfig">
                    <span class="iconfont iconanalysis-chart"></span>
                    <span class="text-info">生产管理</span>
                </li>
                <li class="item" @click="showConfig">
                    <span class="iconfont iconanalysis-chart"></span>
                    <span class="text-info">原材料管理</span>
                </li>
                <li class="item" @click="showConfig">
                    <span class="iconfont iconanalysis-chart"></span>
                    <span class="text-info">客户管理</span>
                </li>
                <li class="item" @click="showConfig">
                    <span class="iconfont iconanalysis-chart"></span>
                    <span class="text-info">运营报表</span>
                </li>
                <li class="item" @click="showConfig">
                    <span class="iconfont iconanalysis-chart"></span>
                    <span class="text-info">领导驾驶舱</span>
                </li>
                <li class="add" title="添加" @click="handleAdd">
                    <span class="iconfont icontianjia1"></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    methods: {
        handleAdd() {
            this.$toast(
                {
                    title: true,
                    text: '新增',
                    type: 'eject',
                    width: '6rem',
                    height: '5rem',
                    t_url: 'dataBaseCenter/dataSharing/addPop',
                    viewPosition: 'view',
                    extr: { closeBtn: {} },
                });
        },
        showConfig() {
            this.$toast(
                {
                    title: true,
                    text: '应用数据库',
                    type: 'sideslip',
                    t_url: 'dataBaseCenter/dataSharing/thematicLibraryConfig',
                    viewPosition: 'view',
                    extr: { closeBtn: {} },
                });
        },
    },
    created() {

    },
    mounted() {

    },
};
</script>
<style lang="stylus" scoped>
.thematicLibrary
    height 100%
    .con
        background #fff
        height 100%
        padding .5rem
        ul
            overflow hidden
            li
                float left
                width 1.8rem
                height 1.1rem
                border 1px solid #ccc
                margin 0 .2rem .2rem 0
                cursor pointer
                &:hover
                    background #4b97e3
                    color #fff
                    .iconfont
                        color #fff
            .item
                text-align center
                color #255998
                font-size .2rem
                position relative
                .iconanalysis-chart
                    position absolute
                    left .08rem
                    top .08rem
                    font-size .26rem
                .text-info
                    width 100%
                    height 100%
                    display inline-block
                    line-height 1.1rem
            .add
                text-align center
                line-height 1.1rem
                .iconfont
                    font-size .28rem
                    color #4b97e3
</style>